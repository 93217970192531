import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { api } from '../services/config';
import { previewCheckin, previewGeneric } from '../services/orders';
type OrderContextProps = {
  customer: any;
  getCustomer: any;
  getPreviewCheckin: any;
  getPreviewGeneric: any;
  preview: any;
};

type OrderContextProviderProps = {
  children: ReactNode;
};

const OrderContext = createContext<OrderContextProps>({
  customer: null,
  getCustomer: () => null,
  getPreviewCheckin: () => null,
  getPreviewGeneric: () => null,
  preview: null,
});

export default function OrderContextProvider({
  children,
}: OrderContextProviderProps) {
  const [customer, setCustomer] = useState(null);
  const [preview, setPreview] = useState(null);

  const getCustomer = useCallback(async (document) => {
    const { data } = await api.get<any>(
      `/v1/partners/orders/customers/${document}`
    );
    setCustomer(data);
    return data;
  }, []);

  const getPreviewCheckin = useCallback(
    async (data: {
      document_number: string;
      order_value_in_cents: number;
      cashback_in_percent: number;
      used_cashback_in_cents: any;
    }) => {
      const dataPreviewCheckin = await previewCheckin(
        data.document_number,
        +data.order_value_in_cents / 100,
        +data.cashback_in_percent,
        data.used_cashback_in_cents
      );
      setPreview(dataPreviewCheckin);
      return dataPreviewCheckin;
    },
    []
  );

  const getPreviewGeneric = useCallback(
    async (data: {
      order_value_in_cents: number;
      cashback_in_percent: number;
      used_cashback_in_cents: any;
      document_number: string;
      cellphone: string;
    }) => {
      const dataPreviewCheckin = await previewGeneric(
        +data.order_value_in_cents / 100,
        +data.cashback_in_percent,
        data.used_cashback_in_cents,
        data.document_number,
        data.cellphone
      );
      setPreview(dataPreviewCheckin);
      return dataPreviewCheckin;
    },
    []
  );
  return (
    <OrderContext.Provider
      value={{ customer, getCustomer, getPreviewCheckin, getPreviewGeneric, preview }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export const useOrderContext = () =>
  useContext<OrderContextProps>(OrderContext);
