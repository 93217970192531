import { api } from './config';

export const fetchCart = async <T>(id: string) => {
  const { status, data } = await api.get(`/v2/partners/services-extras/carts`, {
    params: { company_id: id },
  });

  if (status === 200 && data) {
    return data as T;
  }
};

export const deleteCartItem = async ({
  package_id,
  token,
}: {
  package_id: string;
  token: string;
}) => {
  return await api.delete(`v2/partners/services-extras/carts/items`, {
    params: { package_id, token },
  });
};

export const addToCart = async <T>(
  se_product_id: string,
  token_cart: string
) => {
  const { status, data } = await api.post(
    `/v2/partners/services-extras/carts/items`,
    {
      package_id: se_product_id,
      token: token_cart,
    }
  );

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const getCart = async () => {
  const { status, data } = await api.get(`/v2/partners/services-extras/carts`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const fetchCartItems = async <T>(id: string) => {
  const { status, data } = await api.get<T>(
    '/v2/partners/services-extras/carts/items',
    {
      params: { company_id: id },
    }
  );

  if (status === 200 && data) {
    return data as T;
  }
};

export const getActiveProducts = async <T>(
  companyId: string,
  limit?: number,
  page?: number
) => {
  const { status, data } = await api.get<T>(
    `/v2/partners/services-extras/products`,
    {
      params: { limit: limit, page: page },
      headers: {
        company_id: companyId ?? '',
      },
    }
  );

  if (status === 200 && data) {
    data as T;
    return { data, status };
  }
};
